import { user } from './../../../models/user';
import { ref } from "vue";
import swal from "@/_helpers/swal";
import moment from "moment";
import { ISortType } from "@/models/backOfficeSupplier/ISortType";
import { IBackOfficeSupplierUser } from "@/models/backOfficeSupplier/IBackOfficeSupplierUser";
import ApiService from "@/_services/api.service";
import router from "@/router";

export interface IUserListToForDropdown {
    uuid: string;
    fullname: string;
}

export interface IUpdateBackOfficeUserList {
    user: string;
    supplier: string;
}

export async function getUsersNotLinkedToBackOfficeSupplier(search: string, supplierUuid: string): Promise<IUserListToForDropdown[]> {
    const users = ref<Array<IUserListToForDropdown>>([]);
    const defaultUrl = `suppliers/not-linked-back-office-user-list/${supplierUuid}`;
    const url = search === "" ? defaultUrl : `${defaultUrl}/${search}`;
    let params = {};
    if (search !== "") {
        params = { ...params, search: search };
    }

    try {
        const { data } = await ApiService.get(url, {
            params: params,
            headers: { Accept: "application/json" },
        });
        data.data.forEach((apiData) => {
            const user: IUserListToForDropdown = {
                uuid: apiData.uuid,
                fullname: apiData.fullname,
            };
            users.value.push(user);
        });
    } catch (error) {
        swal.error();
    }

    return users.value;
}

export async function getPaginateBackOfficeUsersBySupplier(
    supplierUuid: string,
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: ISortType,
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const suppliers = Array<IBackOfficeSupplierUser>();
    const tableData = ref<Array<IBackOfficeSupplierUser>>(suppliers);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria,
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {...params, [fieldLabel]: fieldValue };
    }
    try {
        const { data } = await ApiService.get("back-office-user/" + supplierUuid, {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((apiData, index: number) => {
            const backOfficeSupplierUser: IBackOfficeSupplierUser = {} as IBackOfficeSupplierUser;
            backOfficeSupplierUser.id = index.toString();
            backOfficeSupplierUser.uuid = apiData.uuid;
            backOfficeSupplierUser.user = apiData.user
            backOfficeSupplierUser.supplier = apiData.supplier;
            backOfficeSupplierUser.isActive = apiData.isActive;
            backOfficeSupplierUser.createdAt = moment(String(apiData.createdAt)).format("DD/MM/YYYY");
            backOfficeSupplierUser.createdBy = apiData.createdBy ?? undefined;
            tableData.value.push(backOfficeSupplierUser);
        });
    } catch (error) {
        swal.error();
    }

    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export async function addExistingUserToBackOfficeList(data: IUpdateBackOfficeUserList): Promise<object> {
    await ApiService.post("back_office_user_suppliers", data)
        .then((response) => {
            const data = response.data.data;
            const userFullName = `${data.user.firstname} ${data.user.lastname}`;
            swal
                .info(`Vous venez de rajouter ${userFullName} au back Office !`)
                .then(function (results) {
                    if (results.isConfirmed) {
                      const randomQuery = Math.random().toString(36).substring(7);
                        setTimeout(() => {
                          router.push( { name: 'supplierDetail', params: { uuid: data.supplier.uuid, ariaSelected: 2 }, query: { random: randomQuery } })
                        }, 1000);
                    }
                });
            return data;
        })
        .catch((error) => {
            const apiResponse = error.response.data;
            let message = "Une Erreur est surevenue";
            if (
                apiResponse.data &&
                null !== apiResponse.data.data &&
                "undefined" !== apiResponse.data.data &&
                apiResponse.data.data.detail
            ) {
                message = apiResponse.data.data.detail;
            } else {
                message = apiResponse.errors[0].message;
            }
            swal.error(message);
        });
    return data;
}

