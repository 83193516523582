import ApiService from "@/_services/api.service";
import { UserWhiteList } from "@/models/securite/whitelist/EmailWhitelist";
import { ref } from 'vue';
export async function fetchUserWhitelists(itemsPerPage, page, searchByEmail) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const tableData = ref<Array<UserWhiteList>>([]);

    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        'user.email': searchByEmail,
    };
    try {
        const { data } = await ApiService.get(`user_white_lists?order[createdAt]=desc`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        tableData.value = data.data['hydra:member'];
    } catch (error) {
        console.error('Error fetching Company whitelists:', error);
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}




export const toggleStatus = async (data: UserWhiteList) => {
    try {
        const updatedData = { uuid: data.uuid, status: data.status };
        const response = await ApiService.patchWithoutSlug(`/user_white_lists`, updatedData);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const deleteEmail = async (uuid: string) => {
    try {
        await ApiService.delete(`/user_white_lists/${uuid}`);
    } catch (error) {
        throw error;
    }
};