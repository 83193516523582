import { IJackpot } from "@/models/product/jackpot/IJackpot";
import ApiService from "../api.service";
import { ref } from 'vue';
import swal from "@/_helpers/swal";
import moment from 'moment/moment';

export async function fetchJackpot(itemsPerPage , page = {}) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const jackpot = Array<IJackpot>();
    const tableData = ref<Array<IJackpot>>(jackpot);

    try {
        const { data } = await ApiService.get('admin/variant_product_reductions', {
            headers: { Accept: 'application/ld+json' },
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item: IJackpot) => {
            tableData.value.push(item);
        });
        
        } catch (error) {
        alert(error)
        swal.error('Une erreur s\'est produite lors de la récupération de jackpot.');
    }

    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export async function updateJackpotVariantProductReductions(uuid: string, data: object) {
    try {
        await ApiService.patch("admin/jackpot-variant-product-reductions/" + uuid, uuid, data);
    } catch (error) {
        swal.error();
    }
}

export function generateFilename(prefix: string) {
    const date = moment().format('YYYY_MM_DD_HH_mm');
    return prefix === 'ogloba_voucher'
        ? `export_codes_ogloba_${date}.csv`
        : `export_${prefix}_${date}.csv`;
}

export async function downloadOrderVoucher(startDate: string, endDate: string) {
    try {
        const { data } = await ApiService.get(`admin/export/order_voucher/${startDate}/${endDate}`,
            { responseType: 'blob' }
        );

        return data;
    } catch (error) {
        swal.error();
    }
}

export async function downloadOglobaVoucher(startDate: string, endDate: string) {
    try {
        const { data } = await ApiService.get(`admin/export/ogloba_voucher/${startDate}/${endDate}`,
            { responseType: 'blob' }
        );

        return data;
    } catch (error) {
        swal.error();
    }
}

export const jackpotService = {
    fetchJackpot,
    generateFilename,
    downloadOrderVoucher,
    downloadOglobaVoucher
}