import Swal from "sweetalert2/dist/sweetalert2.js";
import 'sweetalert2/src/sweetalert2.scss';

function success(title: string, message: string): Swal {
    return Swal.fire({
        title: title,
        text: message,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
            confirmButton: "btn btn-primary",
        },
    });
}

function confirm(
    message: string,
    cancelButtonText?: string,
    confirmButtonText?: string,
    confirmButtonClass?: string,
    cancelButtonClass?: string
): Swal {
    return Swal.fire({
        text: message,
        icon: "warning",
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: cancelButtonText || "Annuler",
        confirmButtonText: confirmButtonText || "Oui",
        allowOutsideClick: false,
        customClass: {
            confirmButton: confirmButtonClass || "btn btn-primary",
            cancelButton: cancelButtonClass || "btn btn-outline-dark",
        },
    });
}

function warning(message: string): void {
    Swal.fire({
        text: message,
        icon: "warning",
        buttonsStyling: false,
        customClass: {
            confirmButton: "btn btn-primary",
        },
    });
}

function info(message: string): Swal {
    return Swal.fire({
        text: message,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
            confirmButton: "btn btn-primary",
        },
    });
}

function hide(): void {
    Swal.close();
}

function error(message?: string): Swal {
    return Swal.fire({
        text: message || "Désolé, il y a eu un problème. Veuillez réessayer.",
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "Ok",
        customClass: {
            confirmButton: "btn btn-primary",
        },
    });
}

export default {
    success,
    hide,
    error,
    warning, 
    confirm,
    info
};
