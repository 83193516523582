import { ref, watch } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { PropertyValue, IAddVariantProductsToPropertyValue } from "@/models/product/property/PropertyValue";
import { useRouter, useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import { fetchProperty } from '@/_services/property/property';

export async function fetchPropertyValuesByProperty(
    itemsPerPage: number,
    page: number,
    searchByValue: string,
    propertyUuid?: string | string[] | null
):Promise<[boolean, number, PropertyValue[]]> {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const propertyValues = Array<PropertyValue>();
    const tableData = ref<Array<PropertyValue>>(propertyValues);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: searchByValue,
    }

    try {
        const { data } = await ApiService.get(`admin/property_values/${propertyUuid}`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item: PropertyValue) => {
          tableData.value.push(item);
        });
    } catch (error) {
        swal.error('An error occurred while fetching property Values.');
    }

    isLoading.value = false;
    return [isLoading.value, totalRecords.value, tableData.value];
}

export async function addVariantProductsToPropertyValue(params: IAddVariantProductsToPropertyValue) {
    try {
        await ApiService.patchWithoutSlug("admin/variant_product_property_value", params);
    } catch (error) {
        swal.error();
    }
}
const usePropertyService = () => {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const loadingIds = ref<Array<string>>([]);
    const editErrorMessage = ref<string>("");

    const propertyUuidRouteParam = ref<string>('');
    const page = ref<number>(1);
    const defaultRowsPerPage = 15;
    const rowsPerPageOptions = [defaultRowsPerPage, 25, 100];
    const rows = ref<number>(15);
    const isLoading = ref<boolean>(true);
    const properties = Array<PropertyValue>();
    const tableData = ref<Array<PropertyValue>>(properties);
    const currentPage = ref<number>(0);
    const criteria = ref<string>('');
    const totalRecords = ref<number>(0);
    const title = ref<string>('Gérer les catégories');
    const editingRows = ref([]);
    const showDialog = ref<boolean>(false);
    const newValue = ref<string>('');
    const errorMessage = ref<string>("");
    
    const initProperty = async () => {
        propertyUuidRouteParam.value = route.params.uuid as string;
        if (propertyUuidRouteParam.value) {
            let property = await fetchProperty(propertyUuidRouteParam.value);
            title.value = `Propriété "${property.name}"`;

            await populateDatatable(rows.value, page.value, criteria.value);
        }
    };

    const populateDatatable = async (itemsPerPage: number, page: number, criteria: string) => {
        const [loadingDt, totalRecordsDt, tableDataDt] = await fetchPropertyValuesByProperty(itemsPerPage, page, criteria, propertyUuidRouteParam.value);
        totalRecords.value = totalRecordsDt;
        isLoading.value = loadingDt;
        tableData.value = tableDataDt;
    };

    const addVariantProductToPropertyValue = (propertyValueUuid: string) => {
        router.push({ name: 'addVariantProductToPropertyValue', params: { uuid: propertyValueUuid, propertyUuid: propertyUuidRouteParam.value } });
    };

    const listVariantProductsOfPropertyValue = (propertyValueUuid: string) => {
        router.push({ name: 'listVariantProductsOfPropertyValue', params: { uuid: propertyValueUuid } });
    };

    const onPaginate = async (e) => {
        showSpinner();
        rows.value = e.rows;
        currentPage.value = e.page;
        page.value = e.page + 1;
        await populateDatatable(e.rows, e.page + 1, criteria.value);
    };

    const onFilterDt = async () => {
        if (criteria.value !== '') {
            showSpinner();
            await populateDatatable(rows.value, page.value, criteria.value);
        }
    };

    const onClearInputFilter = async () => {
        if (criteria.value === '') {
            showSpinner();
            await populateDatatable(rows.value, page.value, '');
        }
    };

    const redirectToLastPage = () => {
        router.back();
    };

    const showSpinner = () => {
        isLoading.value = true;
    };

    const hideSpinner = () => {
        isLoading.value = false;
    };
    
  const onRowEditSave = async (event) => {
    const { data, newData } = event;
    if (tableData.value.some(item => item.value.toLowerCase() === newData.value.toLowerCase() && item.uuid !== data.uuid)) {
      toast.add({ severity: 'error', summary: 'Erreur', detail: 'Cette property_value existe déjà, vous devez choisir un autre nom.', life: 4000 });
      return;
    }
    try {
      await ApiService.patchWithoutSlug(`admin/variant_product_property_value/update/${data.uuid}`, {
        value: newData.value,
      });
      const index = tableData.value.findIndex(item => item.uuid === data.uuid);
      if (index !== -1) {
        tableData.value[index] = { ...data, value: newData.value };
      }
        toast.add({ severity: 'success', summary: 'Succès', detail: 'Vous venez de modifier une property_value!', life: 2000 });
    } catch (error) {
        toast.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de la mise à jour.', life: 2000 });
    }
  };
    const addNewValue = async () => {
        if (newValue.value.trim() === "") {
            errorMessage.value = 'La valeur ne peut pas être vide.';
            return;
        }
        if (checkDuplicateName(newValue.value)) {
            errorMessage.value = 'Cette property_value existe déjà, vous devez choisir un autre nom.';
            return;
        }
        try {
            await ApiService.post(`/admin/property/add_property_value/${propertyUuidRouteParam.value}`, {
                value: newValue.value,
            });

            await populateDatatable(rows.value, page.value, criteria.value);

            showDialog.value = false;
            newValue.value = "";
            errorMessage.value = "";
            toast.add({ severity: 'success', summary: 'Succès', detail: 'Vous venez de créer une nouvelle property_value!', life: 2000 });
        } catch (error:any) {
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage.value = error.response.data.message;
            } else {
                console.error("Error adding new value: ", error);
                toast.add({ severity: 'error', summary: 'Erreur', detail: 'Une erreur est survenue lors de l\'ajout.', life: 2000 });
            }
        }
    }


    const checkDuplicateName = (value: string): boolean => {
        return tableData.value.some((item) => item.value.toLowerCase() === value.toLowerCase());
    };
    const cancelNewValue = () => {
        newValue.value = "";
        errorMessage.value = "";
        showDialog.value = false;
    };
    watch(newValue, (newVal) => {
        if (tableData.value.some(item => item.value.toLowerCase() === newVal.toLowerCase())) {
            errorMessage.value = 'Cette valeur existe déjà.';
        } else {
            errorMessage.value = '';
        }
    });
    return {
        propertyUuidRouteParam,
        page,
        defaultRowsPerPage,
        rowsPerPageOptions,
        rows,
        isLoading,
        tableData,
        currentPage,
        criteria,
        totalRecords,
        title,
        editingRows,
        showDialog,
        newValue,
        initProperty,
        populateDatatable,
        addVariantProductToPropertyValue,
        listVariantProductsOfPropertyValue,
        onPaginate,
        onFilterDt,
        onClearInputFilter,
        redirectToLastPage,
        onRowEditSave,
        addNewValue,
        showSpinner,
        hideSpinner,
        loadingIds,
        errorMessage,
        cancelNewValue
    };
};

export default usePropertyService;