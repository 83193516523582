import moment from "moment";

const dateFormat = {
  'fr': 'DD-MM-YYYY',
  'en': 'YYYY-MM-DD',
}

export const getFirstDayOfMonth = (format = 'en'): string => {
  return moment().startOf('month').format(dateFormat[format]);
}

export const getTodayDate = (format = 'en'): string => {
  return moment().format(dateFormat[format]);
}