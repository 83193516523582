import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { ICategoryFilter } from "@/models/filter/category/ICategoryFilter";
import {ICategoryFilterForm} from "@/models/filter/category/ICategoryFilterForm";
import router from "@/router";

export async function getFiltersNamesNotLinkedToCategory(categoryUuid: string) {
    const loading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const categoryFilter = Array<ICategoryFilter>();
    const filters = ref<Array<ICategoryFilter>>(categoryFilter);

    try {
        const { data } = await ApiService.get(
            `admin/prototype-not_linked-to-category/${categoryUuid}`, {
                headers: { Accept: "application/ld+json" },
            });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index) => {
            item.id = index;
            filters.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    loading.value = false;
    return [loading, filters];
}

export async function getFiltersNamesLinkedToCategory(categoryUuid: string) {
    const loading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const categoryFilter = Array<ICategoryFilter>();
    const tableData = ref<Array<ICategoryFilter>>(categoryFilter);
    
    try {
        const { data } = await ApiService.get(
          `admin/master_product_filters/category/${categoryUuid}`, {
            headers: { Accept: "application/ld+json" },
          });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index) => {
            item.id = index;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    loading.value = false;
    return [loading, tableData];
}

export async function createCategoryFilter(data: ICategoryFilterForm): Promise<object> {
    try {
        await ApiService.post("admin/master_product_filters", data);
    } catch (e) {
        swal.error("Une Erreur est surevenue à la création du filtre");
    }
    return data;
}

export async function toggleFilterStatus(filterUuid: string, state: boolean) {
    try {
        await ApiService.patchWithoutSlug(`admin/master_product_filters/${filterUuid}`, {
            isActive: state,
        });
    } catch (e) {
        swal.error();
    }
}

export async function deleteCategoryFilter(filterUuid: string) {
    try {
        await ApiService.delete(`admin/master_product_filters/${filterUuid}`);
    } catch (e) {
        swal.error();
    }
}

export const filterService = {
    getFiltersNamesNotLinkedToCategory,
    getFiltersNamesLinkedToCategory,
    createCategoryFilter,
    toggleFilterStatus,
    deleteCategoryFilter
}