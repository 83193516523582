import moment from "moment";

export const validateFileExtension = function (node, fileExtension) {
  return node.value[0].name.endsWith(`.${fileExtension}`);
}

export const checkDatesIntervalRules = (node) => {
  /**
   * node here is from formkit, it's not a native js node.
   * This allows us to get formkit input data with the .at method
   */
  const parent = node.at('$parent');
  // Here we get the startDate input inside our formKit
  const startDateNode = parent.at('startDate');
  const endDateNode = parent.at('endDate');

  if (!startDateNode || !endDateNode) {
    console.error('You must define a start and a end date input.');
    return false;
  }

  if (!startDateNode.value || !endDateNode.value) {
    return true; // Validation passes if either date is not set
  }

  // Ensure that startDate is before or the same as endDate
  return moment(startDateNode.value).isSameOrBefore(endDateNode.value);
}
