
import { productService } from "@/_services/product/product";
import { downloadSoldDocumentCsv } from "@/_services/document/document";
import { downloadSoldCodesCsv } from "@/_services/code/code";
import { jackpotService } from "@/_services/jackpot/jackpot";
import { downloadRefundedPaymentCsv } from "@/_services/transaction/paymentService";

export const exportTypes = {
  sold_documents: {
    label: "Export des ventes de documents",
    fileNamePrefix: "Ventes_de_documents_",
    description: "Cet export contient la liste des documents vendus sur une période spécifique, incluant les produits associés, ainsi que le numéro de commande et l'utilisateur lié à chaque vente.",
    callback: downloadSoldDocumentCsv,
  },
  sold_products: {
    label: "Export des ventes par produit",
    hasSupplierFilter: true,
    fileNamePrefix: "Ventes_de_produits_",
    description: "Cet export contient la liste des produits vendus sur une période spécifique, avec des informations détaillées telles que le prix d'achat, le taux de TVA, le prix de vente à la date de vente, ainsi que le numéro de commande et l'utilisateur associé à chaque vente. Cette liste peut être filtrée par fournisseur.",
    callback: productService.downloadSoldProductsCsv,
  },
  sold_codes: {
    label: "Export des ventes de codes",
    fileNamePrefix: "ventes_de_codes_",
    description: "Cet export contient la liste des codes vendus sur une période spécifique, incluant les produits associés, ainsi que le numéro de commande et l'utilisateur lié à chaque vente.",
    callback: downloadSoldCodesCsv,
  },
  jackpot_vouchers: {
    label: "Export des ventes Jackpot",
    fileNamePrefix: "export_voucher_jackpot_",
    description: "Cet export contient une liste des voucher Jackpot vendus sur une période spécifique, incluant le voucher, le numéro de commande et les statuts associés. Cet export permet de croiser nos données de commandes avec celles du prestataire.",
    callback: jackpotService.downloadOrderVoucher,
  },
  ogloba_codes: {
    label: "Export des ventes Ogloba",
    fileNamePrefix: "export_codes_ogloba_",
    description: "Cet export contient une liste des codes Ogloba vendus sur une période spécifique, incluant le code, le numéro de commande et les statuts associés. Cet export permet de croiser nos données de commandes avec celles du prestataire.",
    callback: jackpotService.downloadOglobaVoucher,
  },
  payment_refunded: {
    label: "Export des transactions remboursées",
    fileNamePrefix: "Export_remboursements_transactions_",
    description: "Cet export contient la liste des transactions remboursées manuellement par l’admin, leur détail ainsi que les informations liées aux commandes associées.",
    callback: downloadRefundedPaymentCsv,
  }
};