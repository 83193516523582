import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import {IMiddleMenu} from "@/models/homepage/IMiddleMenu";


export async function fetchByUuid(uuid: string):Promise<IMiddleMenu> {
    const middleMenu: IMiddleMenu = {} as IMiddleMenu;
    try {
        const { data } = await ApiService.get("middle_menus/" + uuid, {
            headers: { Accept: "application/ld+json" },
        });
        const apiData = data.data;
        middleMenu.uuid = apiData.uuid;
        middleMenu.position = apiData.position;
        middleMenu.title = apiData.title;
    } catch (error) {
        swal.error();
    }

    return middleMenu;
}

export async function fetchAll(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    }
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const middleMenu = Array<IMiddleMenu>();
    const tableData = ref<Array<IMiddleMenu>>(middleMenu);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria,
        archived: false,
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
            ["archived"]: false,
        };
    }

    try {
        const { data } = await ApiService.get("middle_menus", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item) => {
            item.uuid = item.uuid;
            item.position = item.position;
            item.title = item.title;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}


export async function fetchAllActive()
{
    const totalActive = ref<number>(0);
    try {
        const { data } = await ApiService.get("middle_menus?active=1", {
            headers: { Accept: "application/ld+json" },
        });
        totalActive.value = data.data["hydra:totalItems"];
    } catch (error) {
        swal.error();
    }

    return totalActive;
}

export async function update(uuid: string, data: any): Promise<any> {
    await ApiService.patch("middle_menus", uuid, data)
        .then(() => {
            return true;
        })
        .catch(() => {
            swal.error();
            return false;
        });
    return data;
}

export async function changeStateMiddleMenu(uuid: string, state: boolean) {
    try {
        await ApiService.patch(`middle_menus`, uuid,{
            active: state,
        });
    } catch (error) {
        swal.error();
    }
}