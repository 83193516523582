import ApiService from "../api.service";
import swal from "@/_helpers/swal";

export async function downloadRefundedPaymentCsv(startDate: string, endDate: string) {
    try {
        const { data } = await ApiService.get(`admin/export/payment_refunded/${startDate}/${endDate}`,
            { responseType: 'blob' }
        );

        return data;
    } catch (error) {
        swal.error();
    }
}