import { ITransporterList } from "@/models/transporter/ITransporterList";
import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import moment from "moment/moment";

export async function triggerTransporterState(uuid: string, state: boolean) {
    try {
        await ApiService.patchWithoutSlug(`transporters/${uuid}`, {
            isActive: state,
        });
    } catch (error) {
        swal.error();
    }
}

export async function deleteTransporter(uuid: string) {
    try {
        await ApiService.patchWithoutSlug(`transporters/${uuid}`, {
            archived: true,
        });
    } catch (error) {
        swal.error();
    }
}

export async function updateTransporter(uuid: string, data: object) {
    try {
        await ApiService.patchWithoutSlug(`transporters/${uuid}`, data);
    } catch (error) {
        swal.error();
    }
}

export async function createTransporter(data: object) {
    try {
        await ApiService.post("transporters", data);
    } catch (error) {
        swal.error();
    }
}

export async function fetchTransporterByUuid(uuid: string) {
    try {
        const { data } = await ApiService.get(`transporters/${uuid}`);
        return data;
    } catch (error) {
        swal.error();
    }
}
export async function fetchTransporter(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    }
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const transporters = Array<ITransporterList>();
    const tableData = ref<Array<ITransporterList>>(transporters);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria,
        archived: false,
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
            ["archived"]: false,
        };
    }

    try {
        const { data } = await ApiService.get("transporters", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index: number) => {
            item.id = index;
            item.createdAt = moment(String(item.createdAt)).format("DD/MM/YYYY");
            item.createdBy = setCreatedBy(item);
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}
const setCreatedBy = (item): string => {
    if (item.createdBy && item.createdBy.firstname && item.createdBy.lastname) {
        return item.createdBy.firstname + " " + item.createdBy.lastname;
    }

    return "";
};