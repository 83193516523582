import ApiService from "@/_services/api.service";
import { CompanyWhitelist } from "@/models/securite/whitelist/IcompanyWhiteLis";
import { ref } from 'vue';
export async function fetchCompanyWhitelists(itemsPerPage, page, searchByCompany) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const tableData = ref<Array<CompanyWhitelist>>([]);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        'company.name': searchByCompany,
    };
    const error = ref<string>("");
    try {
        const { data } = await ApiService.get(`company_white_lists?order[createdAt]=desc`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        tableData.value = data.data['hydra:member'];
    } catch (err: any) {
       error.value = err.message;
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData, error];
}




export const toggleStatus = async (data: CompanyWhitelist) => {
    try {
        const updatedData = { uuid: data.uuid, status: data.status };
        const response = await ApiService.patchWithoutSlug(`/company_white_lists`, updatedData);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const deleteCompany = async (uuid: string) => {
    try {
        await ApiService.delete(`/company_white_lists/${uuid}`);
    } catch (error) {
        throw error;
    }
};