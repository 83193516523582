import { defineStore } from "pinia";
import { ref } from "vue";
import { User } from "@/models/user/User";
import ApiService from "@/_services/api.service";
import JwtService from "@/_services/jwt.service";
import router from "@/router";

export const useAuthStore = defineStore("auth", () => {
  const errors = ref({});
  const user = ref<Partial<User>>({});
  const isUserAuthenticated = ref<boolean>(!!JwtService.getToken());


  function setError(error) {
    errors.value = { ...error };
  }

  function setAuth(user) {
    isUserAuthenticated.value = true;
    user.value = user;
    errors.value = {};
    JwtService.saveToken(user.token);
    JwtService.saveRefreshToken(user.refresh_token);



  }

  function purgeAuth() {
    isUserAuthenticated.value = false;
    user.value = {} as User;
    errors.value = [];
    JwtService.destroyToken();
    router.push('/')
  }
  function login(credentials) {
    return ApiService.post("/login_check", credentials)
      .then(({ data }) => {
          if (!data.data || !data.data.roles || !data.data.roles.includes("ROLE_ADMIN")) {
              throw new Error(`not authorized.`);
          }
          setAuth(data.data);
      })
      .catch(({ response }) => {
          console.log(response.errors[0].message)
        setError(`not authorized.`); 
      });
  }

  function logout() {
    purgeAuth();
  }

  function register(credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        setAuth(data.data);
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function forgotPassword(payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        setError({});
      })
      .catch(({ response }) => {
        setError(response.data.errors);
      });
  }

  function verifyAuth(payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("token/refresh", payload)
        .then(({ data }) => {
          setAuth(data.data);
        })
        .catch(({ response }) => {
          setError(response.data.data);
          setError(response.data.data);
          purgeAuth();
        });
    } else {
      purgeAuth();
    }
  }

  return {
    errors,
    user,
    isUserAuthenticated,
    login,
    logout,
    register,
    forgotPassword,
    verifyAuth
  };
});