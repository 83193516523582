import { fr } from '@formkit/i18n'
import { DefaultConfigOptions } from '@formkit/vue'
import { generateClasses } from '@formkit/themes'
import { genesisIcons } from '@formkit/icons'
import myTailwindTheme from '@/assets/tailwind-theme'
import { defaultConfig } from '@formkit/vue'
import '@formkit/addons/css/multistep'
import { createMultiStepPlugin } from '@formkit/addons'
import { createProPlugin, rating, toggle, repeater, taglist, autocomplete, datepicker, mask } from '@formkit/pro';
import * as CustomRules from '@/modules/formkit/rules'

import '@formkit/themes/genesis'
import '@formkit/pro/genesis'

const config: DefaultConfigOptions = {
  locales: { fr },
  locale: 'fr',
}
const proPlugin = createProPlugin('fk-4cf116462', {
  rating,
  toggle,
  repeater,
  taglist,
  autocomplete,
  datepicker, 
  mask
  // ... and any other Pro Inputs
})


const multiStepPlugin = createMultiStepPlugin({
  tabStyle: 'progress',
  allowIncomplete: false,
})

export default defaultConfig({
  plugins: [multiStepPlugin, proPlugin],

  icons: {
    ...genesisIcons,
  },
  config: {
    classes: generateClasses(myTailwindTheme),
  },
  rules: {...CustomRules}
})



