import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
export interface IShippingMethod {
  uuid: string;
  name: string;
  deliveryTime: string;
  shippingRuleByPriceStatus: boolean;
  shippingRuleByQuantityStatus: boolean;
  shippingRuleByProductStatus: boolean;
  shippingRules: undefined | Array<string>;
  status: boolean;
  supplier: object;
  transporter: object;
  createdAt?: string;
  createdBy?: string;
}
export async function triggerShippingMethodState(uuid: string, state: boolean) {
    try {
        await ApiService.patchWithoutSlug(`shipping_methods/${uuid}`, {
            status: state ? 1 :0,
        });
    } catch (error) {
        swal.error();
    }
}

export async function fetchShippingMethodBySupplier(
    supplierUuid: string,
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    }
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const shippingMethod = Array<IShippingMethod>();
    const tableData = ref<Array<IShippingMethod>>(shippingMethod);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue
        };
    }

    try {
        const { data } = await ApiService.get("suppliers/" + supplierUuid + "/shipping_methods", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index: number) => {
            item.id = index;
            item.createdBy = setCreatedBy(item);
            item.status = item.status == 1;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

const setCreatedBy = (item): string => {
    if (item.createdBy && item.createdBy.firstname && item.createdBy.lastname) {
        return item.createdBy.firstname + " " + item.createdBy.lastname;
    }

    return "-";
};

export async function createShippingMethod(data: any): Promise<object> {
  await ApiService.post("shipping_methods", formatDataForApi(data))
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
  return data;
}
const formatDataForApi = (data) => {
  const shippingRules = combineShippingRules(
    data.shipping_rules_by_price ?? [],
    data.shipping_rules_by_quantity ?? [],
    data.shipping_rules_by_product ?? []);
  data.transporter = "/api/transporters/" + data.transportor;
  data.shippingRules = shippingRules;
  data.supplier = "/api/suppliers/" + data.supplierUuid;

  return data;
}
export async function updateShippingMethod(uuid: string,data: any): Promise<any> {
  await ApiService.put("shipping_methods/" + uuid, formatDataForApi(data))
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
  return data;
}

export async function getShippingMethod(uuid) {
  try {
    const { data } = await ApiService.get("shipping_methods/" + uuid);
    return data.data;
  } catch (error) {
    return null;
  }
}

function combineShippingRules(shippingRulesByPrice, shippingRulesByQuantity, shippingRulesByProduct) {
  shippingRulesByPrice.forEach(rule => {
    rule.startingFrom = parseFloat(rule.startingFrom);
    rule.price = parseFloat(rule.price);
    rule.type = 1;
  });

  shippingRulesByQuantity.forEach(rule => {
    rule.startingFrom = parseInt(rule.startingFrom);
    rule.price = parseFloat(rule.price);
    rule.type = 2;
  });

  shippingRulesByProduct.forEach(rule => {
    rule.startingFrom = parseInt(rule.startingFrom);
    rule.price = parseFloat(rule.price);
    rule.type = 3;
  });
  return [...shippingRulesByPrice, ...shippingRulesByQuantity, ...shippingRulesByProduct];
}

export function getIndexErrorStartingFrom(shippingRules) {
  if (shippingRules && shippingRules.length === 1) {
    return -1;
  }
  for (let i = 1; i < shippingRules.length; i++) {
    const currentStartingFrom = parseFloat(shippingRules[i].startingFrom);
    const previousStartingFrom = parseFloat(shippingRules[i - 1].startingFrom);
    if (currentStartingFrom <= previousStartingFrom) {
      return i;
    }
  }
  return -1;
}

export async function deleteShippingMethod(uuid: string) {
    try {
        await ApiService.delete("shipping_methods/"+uuid);
        return true;
    } catch (error) {
        return false;
    }
}