import { defineStore } from 'pinia';

export const useProductStore = defineStore('product', {
  state: () => ({
    currentProductPrototype: '6a3f537e-fdfd-11ed-a0b1-de1c3c38600f',
  }),
  actions: {
    
    setCurrentProductPrototype(newValue= '6a3f537e-fdfd-11ed-a0b1-de1c3c38600f') {
      this.currentProductPrototype = newValue;
    },
    getProductPrototype() {
       return this.currentProductPrototype;
      },
  },
});