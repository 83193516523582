
export function getItemTypeOptions() {
    return [
        { value: '0', label: 'Selectionnez le type' },
        { value: '1', label: 'Produit' },
        { value: '2', label: 'Catégorie' },
        { value: '3', label: 'Mot(s) recherché(s)' },
        { value: '4', label: 'Lien de redirection' },
        { value: '5', label: 'Filtre' },
    ];
}
