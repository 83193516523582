<template>
    <div class="picto-wrapper">
        <img class="picto static md:absolute ml-0  mt-40 md:ml-[-100px] md:mt-[-27px]" src="@/assets/img/ceLogo2.svg"  alt="logo"/>
    </div>
</template>
<style scoped>

.picto{
    left: 50%;
    top: 50%;
    width: 400px;
    max-width: 100%;
    opacity: 0.3;
}

</style>

