import { App } from 'vue';
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from "primevue/inputswitch";
import Tag from "primevue/tag";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import Message from 'primevue/message';
import Toast from 'primevue/toast';
import RadioButton from 'primevue/radiobutton';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
export default {
    install(app: App) {
        app.component('Button', Button);
        app.component('DataTable', DataTable);
        app.component('Column', Column);
        app.component('InputSwitch', InputSwitch);
        app.component('Tag', Tag);        
        app.component('TabView', TabView);
        app.component('TabPanel', TabPanel);
        app.component('Dialog', Dialog);
        app.component('ConfirmDialog', ConfirmDialog);
        app.component('Dropdown', Dropdown);
        app.component('InputText', InputText);
        app.component('Message', Message);
        app.component('Toast', Toast);
        app.component('RadioButton', RadioButton);
    }
};
