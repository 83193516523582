import { ref } from "vue";
import swal from "@/_helpers/swal";
import ApiService from "@/_services/api.service";
import {
    IDupliacteMasterProduct,
    IMasterProduct,
    IPatchProductStatusByBatchPlayload
} from "@/models/product/IMasterProduct";
import Axios from "axios"

const fetchProduct = async (
    itemsPerPage: number,
    page: number,
    criteria?: string | undefined,
    supplierUuid?: string | undefined
): Promise<[boolean, number, IMasterProduct[]]> => {
  const isLoading = ref<boolean>(true);
  const totalRecords = ref<number>(0);
  const masterProducts = Array<IMasterProduct>();
  const tableData = ref<Array<IMasterProduct>>(masterProducts);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        searchByAdminProductName: criteria,
        supplierUuid: supplierUuid,
        sortBy: "adminProductName"
    };

  try {
    const { data } = await ApiService.get("master-product", {
        params:params,
      headers: { Accept: "application/ld+json" },
    });
    totalRecords.value = data.data["hydra:totalItems"];
    data.data["hydra:member"].forEach((item, index: number) => {
     // item.id = index;
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error();
  }

  isLoading.value = false;
  return [isLoading.value,totalRecords.value, tableData.value];
}

const deleteMasterProduct = async (uuid: string) => {
  try {
    await ApiService.delete(`master-product/disable/${uuid}`);
  } catch (error) {
    swal.error();
  }
}

const getProduct = (uuid) => {
  return Axios.get('/master-product/'+uuid)
  
}

const getShippingMethodeDependingOnSupplier = (supplierUuuid) => {
  ApiService.get(`suppliers/${supplierUuuid}/shipping_methods?status=1`).then(({data}) => {
    
    alert(supplierUuuid)
  });  
}

const updateProductsStatusByBatch = async (params: IPatchProductStatusByBatchPlayload) => {
    try {
        await ApiService.patchWithoutSlug('admin/master_products_status', params);
    } catch (error) {
        swal.error();
    }
}

const downloadSoldProductsCsv = async (startDate: string, endDate: string, supplierId: string | null) => {
  try {
      const filters = supplierId ? `?supplierId=${supplierId}` : '';

      const { data } = await ApiService.get(`admin/export/sold_product/${startDate}/${endDate}${filters}`,
          { responseType: 'blob' }
      );
      
      return data;
  } catch (error) {
      swal.error();
  }
}

const duplicateMasterProduct = async (data: IDupliacteMasterProduct) => {
    try {
       const response = await ApiService.post(`master-product/duplicate`, data);
       return !response.data.hasError;
    } catch (error) {
        return false;
    }
}

const fetchProductRestrictions = async (
    itemsPerPage: number,
    page: number,
    searchByCompany: string
): Promise<[boolean, number, IMasterProduct[]]> => {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const masterProducts = Array<IMasterProduct>();
    const tableData = ref<Array<IMasterProduct>>(masterProducts);
    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: searchByCompany,
    };

    try {
        const { data } = await ApiService.get("/admin/master-product-company", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item: IMasterProduct) => {
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    isLoading.value = false;
    return [isLoading.value,totalRecords.value, tableData.value];
}

export const productService = {
  getProduct,
  deleteMasterProduct,
  fetchProduct,
  getShippingMethodeDependingOnSupplier,
  updateProductsStatusByBatch,
  downloadSoldProductsCsv,
  duplicateMasterProduct,
  fetchProductRestrictions
}