import { StaticOptionConstant } from "@/models/modules/constants/StaticOptionConstant"

export const urssafEvent: StaticOptionConstant = {
  uuid: 'e7d653d5-fa45-11ed-a0b1-de1c3c38600f',
  name: 'Urssaf Event',
  values: [
    {label: 'Naissance', value: '4bf6fc49-10e4-11ee-961e-024264400006', databaseValue: '1'},
    {label: 'Mariage', value: '4bf9191a-10e4-11ee-961e-024264400006', databaseValue: '2'},
    {label: 'Retraite', value: '4bfae864-10e4-11ee-961e-024264400006', databaseValue: '3'},
    {label: 'Noël adulte', value: '4bfd3e6e-10e4-11ee-961e-024264400006', databaseValue: '4'},
    {label: 'Noël enfant', value: '4bff0f2a-10e4-11ee-961e-024264400006', databaseValue: '5'},
    {label: 'Rentrée scolaire', value: '4c01591e-10e4-11ee-961e-024264400006', databaseValue: '6'},
    {label: 'Fête des mères/pères', value: '4c03b54c-10e4-11ee-961e-024264400006', databaseValue: '7'},
    {label: 'Sainte Catherine / Saint Nicolas', value: '4c05c2ac-10e4-11ee-961e-024264400006', databaseValue: '8'},
  ],
}

export const urssafClassification: StaticOptionConstant = {
  uuid: 'e7d49bb6-fa45-11ed-a0b1-de1c3c38600f',
  name: 'Urssaf Classificiation',
  values: [
    {label: 'Culture', value: '4bede92c-10e4-11ee-961e-024264400006', databaseValue: '1'},
    {label: 'Vacances/Voyages', value: '4bf04945-10e4-11ee-961e-024264400006', databaseValue: '2'},
    {label: 'Sport', value: '4bf2200d-10e4-11ee-961e-024264400006', databaseValue: '3'},
    {label: 'Autre', value: '4bf45018-10e4-11ee-961e-024264400006', databaseValue: '4'},
  ],
}

export const PRODUCT_PROTOTYPES = 
    {
      TICKET: '6a3f537e-fdfd-11ed-a0b1-de1c3c38600f', 
      GIFT: '6a3fe435-fdfd-11ed-a0b1-de1c3c38600f', 
      PHYSICAL: '6a3fc06d-fdfd-11ed-a0b1-de1c3c38600f', 
      PROMO: '6a3ffb8f-fdfd-11ed-a0b1-de1c3c38600f', 
      MEMBER: '6a401dea-fdfd-11ed-a0b1-de1c3c38600f',
    }
