import router from '@/router';
import { useAuthStore } from "@/stores/auth/authStore";
import JwtService from '@/_services/jwt.service';

export function authGuard(to, from, next){
    const token = localStorage.getItem('token')
    const authStore = useAuthStore();
    authStore.verifyAuth({ api_token: JwtService.getToken(), refresh_token: JwtService.getRefreshToken() });
    if(token){
        return true
    }
    router.push({
        path: '/',
        query: { redirect: to.fullPath }
    })
}