import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import {IMiddleMenuItem} from "@/models/homepage/IMiddleMenuItem";

export async function fetchMiddleMenuItemByUuid(uuid: string):Promise<IMiddleMenuItem> {
    const middleMenuItem: IMiddleMenuItem = {} as IMiddleMenuItem;
    try {
        const { data } = await ApiService.get("middle_menu_items/" + uuid, {
            headers: { Accept: "application/ld+json" },
        });
        const apiData = data.data;
        middleMenuItem.uuid = apiData.uuid;
        middleMenuItem.position = apiData.position;
        middleMenuItem.title = apiData.title;
        middleMenuItem.label = apiData.label;
        middleMenuItem.description = apiData.description;
        middleMenuItem.imageWeb = apiData.imageWeb;
        middleMenuItem.imageMobile = apiData.imageMobile;
        middleMenuItem.product = apiData.product ? apiData.product.uuid : '';
        middleMenuItem.filter = apiData.filter ? apiData.filter.uuid : '';
        middleMenuItem.middleMenu = apiData.middleMenu;
        middleMenuItem.redirectionLink = apiData.redirectionLink ?? '';

        middleMenuItem.itemType = apiData.itemType ?? '';
        middleMenuItem.itemValue = apiData.itemValue ?? '';
        middleMenuItem.middleMenu = apiData.middleMenu.uuid ?? '';
    } catch (error) {
        swal.error();
    }

    return middleMenuItem;
}

export async function fetchAll(
    uuid: string,
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    }
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const middleMenu = Array<IMiddleMenuItem>();
    const tableData = ref<Array<IMiddleMenuItem>>(middleMenu);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue
        };
    }

    try {
        const { data } = await ApiService.get("middle_menu_items?middleMenu.uuid="+uuid, {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item) => {
            item.uuid = item.uuid;
            item.position = item.position;
            item.title = item.title;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export async function update(uuid: string, data: any): Promise<any> {
    await ApiService.patch("middle_menu_items", uuid, data)
        .then(() => {
            return true;
        })
        .catch(() => {
            swal.error();
            return false;
        });
    return data;
}

export async function deleteMiddleMenuItem(uuid: string): Promise<boolean> {
    try {
        await ApiService.delete("middle_menu_items/" + uuid);
        return true;
    } catch (error) {
        swal.error();
        return false;
    }
}