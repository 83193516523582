import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";

export async function fetchMegaMenuCategories(page: number, rows: number) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const tableData = ref([]);

    try {
        const { data } = await ApiService.get(`mega_menu_categories?page=${page}&itemsPerPage=${rows}`, {
            headers: { Accept: 'application/ld+json' },
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item:never) => {
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error('Une erreur est survenue');
        console.error(error); 
    }

    isLoading.value = false;
    return { isLoading, tableData, totalRecords };
}

export async function deleteMegaMenuCategories(uuid) {
    await ApiService.delete('mega_menu_categories/'+uuid)
}
