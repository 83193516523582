<template>
    <h1 class="flex justify-between">
      <div>
        <span class="page-title">
          <slot name="titleName"></slot>
        </span>
      </div>
      <slot name="titleAction"></slot>
    </h1>

    <main>
      <slot name="content"></slot>
    </main>
</template>

<style scoped>
main {
   background: white;
}
</style>