import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { ICategory } from "@/models/category/ICategory";
import {ICategoryForDropDown} from "@/models/category/ICategoryForDropDown";
export async function fetchPaginatedCategories(
    itemsPerPage: number,
    page: number,
    uuid?: string | string[] | null,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    },
    lvl?: number,
    name?: string
) {
    const loading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const categories = Array<ICategory>();
    const tableData = ref<Array<ICategory>>(categories);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        lvl: lvl,
        name: name,
    };
    if (!uuid && lvl === undefined) {
        params = { ...params, lvl: 1 };
    }

    if (criteria !== "") {
        params = { ...params, name: criteria?.trim() };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
        };
    }

    try {
        const { data } = await ApiService.get(
            "categories" + (uuid ? "?parent.uuid=" + uuid : ""),
            {
                params: params,
                headers: { Accept: "application/ld+json" },
            }
        );

        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item, index) => {
            item.position = itemsPerPage * (page - 1) + index + 1;
            item.hasChildren = hasChildrenFunc(item.children);
            item.id = index;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }

    loading.value = false;
    return [loading, totalRecords, tableData];
}

export async function fetchCategory(uuid: string):Promise<ICategory> {
    const category: ICategory = {} as ICategory;
    try {
        const { data } = await ApiService.get("categories/" + uuid, {
            headers: { Accept: "application/ld+json" },
        });
        const apiData = data.data;
        category.id = apiData.id;
        category.uuid = apiData.uuid;
        category.name = apiData.name;
        category.slug = apiData.slug;
        category.description = apiData.description ?? '';
        category.translations = apiData.translations;
        category.enabled = apiData.enabled;
        category.archived = apiData.archived;
        category.lvl = apiData.lvl;
        category.rgt = apiData.rgt;
        category.lft = apiData.lft;
        category.position = apiData.position;
        category.hasChildren = hasChildrenFunc(apiData.children);
        category.children = apiData.children;
        category.vignette = apiData.vignette;
        category.parent = apiData.parent;
        category.activeMasterProductsCount = apiData.activeMasterProductsCount;
        if (category.parent) {
            const parentUri = getCategoryParentUuid(category)
            if (parentUri) {
                fetchCategory(parentUri).then((response) => {
                    category.parentObject = response
                })
            }
        }
    } catch (error) {
        swal.error();
    }

    return category;
}

const hasChildrenFunc = (subCategories): boolean => {
    for (let i = 0; i < subCategories.length; i++) {
        if (!subCategories[i].archived) {
            return true;
        }
    }
    return false;
};

export function getCategoryParentUuid(category: ICategory): string | null {
    let categoryParentUuid: string | null = null;
    if (null != category.parent) {
        categoryParentUuid = category.parent.replace(/^\/api\/categories\//, "");
    }

    return categoryParentUuid;
}

export function canAddProductFilterToCategory(category: ICategory): boolean {
    return !(null === category.activeMasterProductsCount
        || "undefined" === typeof (category.activeMasterProductsCount)
        || 0 === category.activeMasterProductsCount);
}

export const categoryService = {
    fetchPaginatedCategories,
    fetchCategory,
    getCategoryParentUuid,
    canAddProductFilterToCategory
}

export async function fetchCategories(): Promise<ICategoryForDropDown[]> {
  const categories: ICategoryForDropDown[] = [];
  try {
    const { data } = await ApiService.get('categories?lvl=1', {
      headers: { Accept: 'application/ld+json' },
    });
    const transformedCategories: ICategoryForDropDown[] = data.data['hydra:member'].map((item: any) => ({
      label: item.name,
      value: item.uuid,
    }));
    categories.push(...transformedCategories);
  } catch (error) {
    swal.error();
  }

  return categories;
}

export async function fetchCategoriesForDropDown(
    itemsPerPage: number,
    page: number,
    criteria?: string
): Promise<[number, ICategoryForDropDown[]]> {
    const totalCategoriesRecordsForDropDown = ref<number>(0);
    const categories = Array<ICategoryForDropDown>();
    const categoriesForDropDown = ref<Array<ICategoryForDropDown>>(categories);

    interface IParams { lvl: number, itemsPerPage: number; page: number; name?: string; }
    const params: IParams = {
        lvl: 1,
        itemsPerPage: itemsPerPage,
        page: page,
        ...(criteria && { search: criteria.trim() })
    };
    
    try {
        const { data } = await ApiService.get('categories', {
            headers: { Accept: 'application/ld+json' },
            params: params,
        });
        totalCategoriesRecordsForDropDown.value = data.data['hydra:totalItems'];
        const transformedCategories: ICategoryForDropDown[] = data.data['hydra:member'].map((item: {uuid: string, name: string}) => ({
            label: item.name,
            value: item.uuid,
        }));
        categoriesForDropDown.value.push(...transformedCategories);
    } catch (error) {
        swal.error();
    }

    return [totalCategoriesRecordsForDropDown.value, categoriesForDropDown.value];
}
