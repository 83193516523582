import moment from "moment";

export const generateFileName = (prefix: string, extension: string) => {
  return `${prefix}${moment().format('YYYY_MM_DD_HH_mm')}${extension}`;
}

export const downloadFile = (fileName: string, url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const generateFileUrl = (data: string) => {
  return window.URL.createObjectURL(new Blob([data]));
}