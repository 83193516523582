import { ref } from "vue";
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import {ICarousel} from "@/models/homepage/ICarousel";

export async function deleteCarousel(uuid: string) {
    await ApiService.delete("carousels/" + uuid)
        .then(() => {
            return true;
        })
        .catch(() => {
            swal.error();
            return false;
        });
}

export async function fetchCarouselByUuid(uuid: string):Promise<ICarousel> {
    const carousel: ICarousel = {} as ICarousel;
    try {
        const { data } = await ApiService.get("carousels/" + uuid, {
            headers: { Accept: "application/ld+json" },
        });
        const apiData = data.data;
        carousel.position = apiData.position;
        carousel.imageWeb = apiData.imageWeb;
        carousel.imageMobile = apiData.imageMobile;
        carousel.itemType = apiData.itemType;
        carousel.itemValue = apiData.itemValue;
        ;
    } catch (error) {
        swal.error();
    }

    return carousel;
}

export async function fetchCarousel(
    itemsPerPage: number,
    page: number,
    criteria?: string,
    sort?: {
        fieldLabel: string;
        fieldValue: string;
    }
) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const carousel = Array<ICarousel>();
    const tableData = ref<Array<ICarousel>>(carousel);
    let params = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: criteria,
    };

    if (criteria !== "") {
        params = { ...params, search: criteria };
    }

    if (sort !== undefined) {
        const { fieldValue, fieldLabel } = sort;
        params = {
            ...params,
            [fieldLabel]: fieldValue,
        };
    }

    try {
        const { data } = await ApiService.get("carousels", {
            params: params,
            headers: { Accept: "application/ld+json" },
        });
        totalRecords.value = data.data["hydra:totalItems"];
        data.data["hydra:member"].forEach((item) => {
            item.uuid = item.uuid;
            item.position = item.position;
            item.imageWeb = item.imageWeb;
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error();
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export async function changeStateCarousel(uuid: string, state: boolean) {
    try {
        await ApiService.patch(`carousels/active`, uuid,{
            active: state,
        });
    } catch (error) {
        swal.error();
    }
}