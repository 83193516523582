import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import Property from "@/models/product/property/Property";
import swal from "@/_helpers/swal";

export async function fetchProperties(
    itemsPerPage: number,
    page: number,
    searchByName: string
): Promise<[boolean, number, Property[]]> {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const properties = Array<Property>();
    const tableData = ref<Array<Property>>(properties);
    const params = {
        specialProperty: true,
        itemsPerPage: itemsPerPage,
        page: page,
        search: searchByName,
    }

    try {
        const { data } = await ApiService.get('admin/properties', {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item: Property) => {
          tableData.value.push(item);
        });
    } catch (error) {
        swal.error('An error occurred while fetching properties.');
    }

    isLoading.value = false;
    return [isLoading.value, totalRecords.value, tableData.value];
}

export async function fetchProperty(uuid: string): Promise<Property> {
    const property: Property = {} as Property;
    try {
        const { data } = await ApiService.get(`properties/${uuid}`, {
            headers: { Accept: 'application/ld+json' },
        });
        const apiResponse = data.data;
        property.uuid = apiResponse.uuid;
        property.name = apiResponse.name;
    } catch (error) {
        swal.error('An error occurred while fetching fetchProperty.');
    }

    return property;
}
