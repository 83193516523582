import ApiService from "@/_services/api.service";
import { IpWhitelist } from "@/models/securite/whitelist/IpWhiteList";
import { ref } from 'vue';

export async function fetchIpWhitelists(itemsPerPage, page, searchByIp) {
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const tableData = ref<Array<IpWhitelist>>([]);

    const params = {
        itemsPerPage: itemsPerPage,
        page: page,
        ipAddress: searchByIp
    };
    try {
        const { data } = await ApiService.get(`ip_white_lists?order[createdAt]=desc`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        tableData.value = data.data['hydra:member'];
    } catch (error) {
        console.error('Error fetching IP whitelists:', error);
    }
    isLoading.value = false;
    return [isLoading, totalRecords, tableData];
}

export const toggleStatus = async (data: IpWhitelist) => {
    try {
        const updatedData = { uuid: data.uuid, status: data.status };
        const response = await ApiService.patchWithoutSlug(`/ip_white_lists`, updatedData);
        return response.data;
    } catch (error) {
        throw error;
    }
};
export const deleteIP = async (uuid: string) => {
    try {
        await ApiService.delete(`/ip_white_lists/${uuid}`);
    } catch (error) {
        throw error;
    }
};