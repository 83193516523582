export function ucfirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function formatStatus(
  status: boolean,
  isHiddenProduct: boolean,
  isClosedSale: boolean
): string {
  if (!status) {
    return "Inactif";
  } else if (isHiddenProduct) {
    return "Caché";
  } else if (isClosedSale) {
    return "Fermé";
  } else if (status) {
    return "Actif";
  } else {
    return "Inactif";
  }
}

