export function getLocalStorageRowsPerPage(localStorageKey: string, defaultRowsPerPage: number) {
  const localStorageData = getLocalStorageData(localStorageKey);
  if (null !== localStorageData && localStorageData.rows) {
    return localStorageData.rows;
  }
  return defaultRowsPerPage;
}

export function getLocalStorageData(localStorageKey: string) {
  const storageData = localStorage.getItem(localStorageKey);
  if (null !== storageData && "undefined" !== storageData) {
    return JSON.parse(storageData);
  }

  return null;
}

export function setLocalStorageRowsPerPage(rowPerPage: number, localStorageKey: string) {
  const storageData = localStorage.getItem(localStorageKey);
  if (null !== storageData) {
    const localStorageObject = JSON.parse(storageData);
    localStorageObject.rows = rowPerPage;
    localStorage.setItem(localStorageKey, JSON.stringify(localStorageObject));
  }
}