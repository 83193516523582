import { ref } from 'vue';
import ApiService from "@/_services/api.service";
import swal from "@/_helpers/swal";
import { ProductVariant } from '@/models/product/variant/ProductVariant';
import {IProductVariantForGrant} from "@/models/product/variant/ProductVariantForGrant";
import {IProductVariantForProperty} from "@/models/product/variant/IProductVariantForProperty";
import {IAddVariantProductsToPropertyValue} from "@/models/product/property/PropertyValue";

export async function fetchProductVariants(params: Record<string, any> | undefined = {}) {
  const isLoading = ref<boolean>(true);
  const totalRecords = ref<number>(0);
  const productVariants = Array<ProductVariant>();
  const tableData = ref<Array<ProductVariant>>(productVariants);

  try {
    const { data } = await ApiService.get('variant-product-filter', {
      params,
      headers: { Accept: 'application/ld+json' },
    });
    
    totalRecords.value = data.data['hydra:totalItems'];
    data.data['hydra:member'].forEach((item: ProductVariant) => {
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error('An error occurred while fetching productVariants.');
  }

  isLoading.value = false;
  return [isLoading, tableData];
}

export async function fetchVariantProductsByPropertyValue(
    itemsPerPage: number,
    page: number,
    searchFilterValue: string,
    matchPropertyValueOnly: boolean,
    propertyValueUuid?: string | string[] | null,
    categoriesUuid?: string[] | undefined,
): Promise<[boolean, number, IProductVariantForProperty[]]> {
    interface IVariantProductByPropertyValueParams {
        itemsPerPage: number;
        page: number;
        search: string;
        matchPropertyValueOnly: boolean,
        propertyValueUuid?: string | string[] | null,
        categories?: string[];
    }
    
    const isLoading = ref<boolean>(true);
    const totalRecords = ref<number>(0);
    const variantProduct = Array<IProductVariantForProperty>();
    const tableData = ref<Array<IProductVariantForProperty>>(variantProduct);
    const params: IVariantProductByPropertyValueParams = {
        itemsPerPage: itemsPerPage,
        page: page,
        search: searchFilterValue,
        matchPropertyValueOnly: matchPropertyValueOnly,
    };

    if (propertyValueUuid && propertyValueUuid.length > 0) {
        params.propertyValueUuid = propertyValueUuid;
    }
    if (categoriesUuid && categoriesUuid.length > 0) {
        params.categories = categoriesUuid;
    }

    try {
        const { data } = await ApiService.get(`admin/variant-product-property-value-bind-state`, {
            headers: { Accept: 'application/ld+json' },
            params: params
        });
        totalRecords.value = data.data['hydra:totalItems'];
        data.data['hydra:member'].forEach((item: IProductVariantForProperty) => {
            tableData.value.push(item);
        });
    } catch (error) {
        swal.error('An error occurred while fetching properties.');
    }

    isLoading.value = false;
    return [isLoading.value, totalRecords.value, tableData.value];
}

export const archiveVariantProduct = (uuid: string) => {
  return ApiService.delete(`variant-product-archive/${uuid}`);
}

export async function fetchProductsForGrant(
  itemsPerPage: number,
  page: number,
  criteria?: string | undefined,
  categoriesUuid?: string[] | undefined,
  variantProductCollectionUuid?: string | undefined
): Promise<[boolean, number, IProductVariantForGrant[]]> {
  const loading = ref<boolean>(true);
  const totalRecords = ref<number>(0);
  const productVariantForGrant = Array<IProductVariantForGrant>();
  const tableData = ref<Array<IProductVariantForGrant>>(productVariantForGrant);
  let params : Record<string, any> = {
    itemsPerPage: itemsPerPage,
    page: page
  };

  if (criteria !== "") {
    params = { ...params, name: criteria };
  }

  if (categoriesUuid && categoriesUuid.length > 0) {
    params = { ...params, categories: categoriesUuid };
  }

  try {
    const baseUri = "/variant-product-grant";
    const paramUri = variantProductCollectionUuid !== undefined
          ? `?variantProductCollectionUuid=${variantProductCollectionUuid}`
          : '';
    const { data } = await ApiService.get(
        baseUri + paramUri,
      {
        params: params,
        headers: { Accept: "application/ld+json" },
      }
    );

    totalRecords.value = data.data["hydra:totalItems"];
    data.data["hydra:member"].forEach((item, index) => {
      item.id = index;
      tableData.value.push(item);
    });
  } catch (error) {
    swal.error();
  }

  loading.value = false;
  return [loading.value, totalRecords.value, tableData.value];
}

export function formatCategoriesProduct(categories)
{
    if (categories.length === 0) return "";
    return categories.map(cat => cat.value).join(", ");
}