import { StaticOptionConstant } from "@/models/modules/constants/StaticOptionConstant"

export const taxes: StaticOptionConstant = {
  uuid: "",
  name: "",
  values: [
    { label: '10%', value: 'f55dfa4c-91ff-47b5-a3ac-11774a9f5dcd', databaseValue: 1.1 },
    { label: '20%', value: 'd2893bca-f63b-40c9-b0ce-7f5f3ff33fce', databaseValue: 1.2 },
    { label: '2,1%', value: '0259b3cf-eb2b-422f-9088-6a31f1862f57', databaseValue: 1.021 },
    { label: '5,5%', value: 'e43d207d-fb94-476f-955c-da598bfee57b', databaseValue: 1.055 },
    { label: '0%', value: 'b8fed660-450f-4119-be79-0ad6c514a2a0', databaseValue: 1 },
  ],
}