<template>
  <router-link
    :to="to"
    class="inline-flex items-center px-4 py-2 border border-blue-500 text-blue-500 bg-white transition-colors duration-300 hover:bg-blue-500 hover:text-white rounded-md text-sm"
    @click.native="onClick"
  >
    <svg class="w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
    </svg>
    {{ $t(label) }}
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>
